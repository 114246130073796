// Function for fading in list items sequentially
function fadeInItemsSequentially(navItems) {
  let delay = 0;
  for (let i = 0; i < navItems.length; i++) {
    setTimeout(() => {
      navItems[i].classList.add('fade-in');
    }, delay);
    delay += 75; // Add 200ms delay for each consecutive items
  }
}

// Listens for domcontenloaded, and then adds evenlisteners to the open/close button on mobile nav
document.addEventListener('DOMContentLoaded', function () {
  const openMenuButton = document.getElementById('open-menu');
  const closeMenuButton = document.getElementById('close-menu');
  const mobileMenu = document.getElementById('mobile-menu');
  const navList = document.getElementById('mobile-nav-list')
  const navItems = navList.getElementsByTagName('li')

  openMenuButton.addEventListener('click', function () {
    fadeInItemsSequentially(navItems)
    mobileMenu.classList.remove('hidden')
  });

  closeMenuButton.addEventListener('click', function () {
    for (let i = 0; i < navItems.length; i++) {
      navItems[i].classList.remove('fade-in');
    }

    mobileMenu.classList.add('hidden')

  });
});
